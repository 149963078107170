import {Directive, ElementRef, HostListener, inject, Renderer2} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import {DvbDateUtil, type FunctionType, isNullish, Nullish} from '@dv/shared/code';

/**
 * Used to display an ngModel containing minutes as hh:mm within a text input.
 */
@Directive({
    selector: '[dvMinutesInput][ngModel]',
    standalone: true,
    providers: [
        {provide: NG_VALUE_ACCESSOR, multi: true, useExisting: MinutesInputDirective},
        {provide: NG_VALIDATORS, multi: true, useExisting: MinutesInputDirective},
    ],
})
export class MinutesInputDirective implements ControlValueAccessor, Validator {

    private readonly elementRef = inject(ElementRef);
    private readonly renderer = inject(Renderer2);

    public onChange?: FunctionType;
    public onTouched?: FunctionType;

    @HostListener('keydown.enter')
    @HostListener('blur')
    private confirmValue(): void {

        const inputValue = this.elementRef.nativeElement.value;
        const minutes = this.parse(inputValue);

        if (!isNullish(minutes)) {
            // write minutes to input
            this.writeValue(minutes);
        }
        // write minutes to model
        this.onChange?.(minutes);
    }

    public writeValue(modelValue: number | Nullish): void {
        if (isNullish(modelValue)) {
            this.renderer.setProperty(this.elementRef.nativeElement, 'value', null);

            return;
        }

        const hours = Math.floor(Math.abs(modelValue) / DvbDateUtil.MINUTES_PER_HOUR);
        const minutes = Math.round(Math.abs(modelValue) % DvbDateUtil.MINUTES_PER_HOUR);
        this.renderer.setProperty(
            this.elementRef.nativeElement,
            'value',
            `${modelValue < 0 ? '-' : ''}${this.pad(hours)}:${this.pad(minutes)}`);
    }

    public setDisabledState(isDisabled: boolean): void {
        this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public validate(_control: AbstractControl): ValidationErrors | null {
        const inputValue: string | Nullish = this.elementRef.nativeElement.value;
        if (isNullish(inputValue) || inputValue === '' || this.isValidInputFormat(inputValue)) {
            return null;
        }

        return {hoursMinutesFormat: inputValue};
    }

    // eslint-disable-next-line complexity
    private parse(value: string): number | null {
        if (isNullish(value) || value.length === 0 || !this.isValidInputFormat(value)) {
            return null;
        }

        let hours;
        let minutes;

        // purposely used || instead of ??, because ?? does not replace NaN with 0
        if (value.includes(':')) {
            const split = value.split(':');
            hours = split[0].length > 0 ? parseInt(split[0], 10) || 0 : 0;
            minutes = split[1].length > 0 ? parseInt(split[1], 10) || 0 : 0;
            if (hours < 0 || parseFloat(value.replace(':', '')) < 0) {
                minutes *= -1;
            }
        } else {
            const inputNumber = parseFloat(value) || 0;
            hours = Math.trunc(inputNumber);
            minutes = inputNumber % 1 * DvbDateUtil.MINUTES_PER_HOUR;
        }

        return (hours ?? 0) * DvbDateUtil.MINUTES_PER_HOUR + (minutes ?? 0);
    }

    private pad(number: number): string {
        return String(number).padStart(2, '0');
    }

    private isValidInputFormat(inputValue: string): boolean {
        return /^\d*[:.]?\d{0,2}$/.test(inputValue);
    }
}
