<ng-container *transloco="let t">
    @if (title()) {
        <h2>{{ title() }}</h2>
    }

    <dvlib-spinner data-testid="centered-spinner" class="centered-spinner" [show]="isLoading()"/>

    @if (isEmpty()) {
        <div class="collapsible-panel-container first-container">
            <p>{{ emptyText() }}</p>
        </div>
    }
    @for (item of items(); track item.entity.id) {
        @if (item.terminated) {
            <dv-collapsible-panel [isFirst]="$first">
                <ng-container panel-header *ngTemplateOutlet="terminateHeader; context: {$implicit: item}">
                    <p>{{ t('COMMON.BEENDET_TITLE', {endedatum: item.entity.gueltigBis | dvLocalDateFormat}) }}</p>
                </ng-container>
                <ng-container panel-content>
                    <ng-container *ngTemplateOutlet="terminateHeader; context: {$implicit: item}"></ng-container>
                    <div class="normal-abstand-top">
                        <dvlib-button-list>
                            <button type="button"
                                    class="btn btn-link"
                                    (click)="extend.emit(item.entity.id)">{{ t('COMMON.BEENDEN_REVERT') }}
                            </button>
                        </dvlib-button-list>
                    </div>
                </ng-container>
            </dv-collapsible-panel>
        }
        <dv-collapsible-panel [isFirst]="$first && !item.terminated"
                              [isCollapsed]="!item.expanded"
                              (isCollapsedChange)="toggleExpanded.emit(item.entity.id)">
            <ng-container panel-header *ngTemplateOutlet="entityHeader; context: {$implicit: item}"></ng-container>
            <ng-container panel-content>
                <ng-container *ngTemplateOutlet="entityHeader; context: {$implicit: item}"></ng-container>

                @if (item.displayMode === 'readonly') {
                    <ng-container *ngTemplateOutlet="readonlyModeTemplate()!; context: {$implicit: item, index: $index}"/>
                    <div class="normal-abstand-top">
                        <dvlib-button-list>
                            <button type="button"
                                    class="btn btn-link"
                                    (click)="editEntity.emit(item.entity)">{{ t('COMMON.BEARBEITEN') }}
                            </button>
                            <button type="button"
                                    class="btn btn-link"
                                    (click)="deleteEntity.emit(item.entity)">{{ t('COMMON.LOESCHEN') }}
                            </button>
                        </dvlib-button-list>
                    </div>

                } @else {
                    <ng-container *ngTemplateOutlet="editModeTemplate()!; context: {$implicit: item, index: $index}"/>
                }
            </ng-container>
        </dv-collapsible-panel>
    }

    <ng-container *ngTemplateOutlet="createModeTemplate() ?? defaultReadonlyModeTemplate"/>

    <ng-template #defaultReadonlyModeTemplate let-item [dvGueltigkeitVerlaufTemplate]="items()"/>

    <ng-template #terminateHeader let-item [dvGueltigkeitVerlaufTemplate]="items()">
        <p>{{ t('COMMON.BEENDET_TITLE', {endedatum: item.entity.gueltigBis | dvLocalDateFormat}) }}</p>
    </ng-template>

    <ng-template #entityHeader let-item [dvGueltigkeitVerlaufTemplate]="items()">
        <p>
            <dv-gueltigkeit [entity]="item.entity"/>
        </p>
    </ng-template>

    <dvlib-confirm-dialog
        [open]="showDeleteDialog()"
        [title]="t('COMMON.CONFIRM_DELETE_ENTITY', {entity: entityName()})"
        [isLoading]="isLoading()"
        [confirmActionText]="t('COMMON.LOESCHEN')"
        [cancelActionText]="t('COMMON.ABBRECHEN')"
        (confirm)="confirmDelete.emit()"
        (cancel)="hideDeleteDialog.emit()"/>
</ng-container>
