/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ChangeDetectionStrategy, Component, computed, input, output} from '@angular/core';
import {ButtonListComponent, LoadingButtonComponent} from '@dv/shared/angular';
import type {Nullish} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';

@Component({
    selector: 'dv-submit-cancel-buttons',
    templateUrl: './submit-cancel-buttons.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TranslocoModule, LoadingButtonComponent, ButtonListComponent],
})
export class SubmitCancelButtonsComponent {
    public isLoading = input<boolean | Nullish>();
    public submitLabel = input<string>();
    public cancelLabel = input<string>();

    public submitKey = computed(() => this.submitLabel() || 'COMMON.SPEICHERN');
    public cancelKey = computed(() => this.cancelLabel() || 'COMMON.ABBRECHEN');

    public readonly cancel = output<void>();
}
