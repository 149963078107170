import {NgClass} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, inject, input} from '@angular/core';
import {ColorMode} from '@dv/kitadmin/models';
import {isNullish} from '@dv/shared/code';
import {Translator} from '@dv/shared/translator';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

@Component({
    selector: 'dv-pensum-icon',
    standalone: true,
    imports: [
        TooltipModule,
        NgClass,
    ],
    templateUrl: './pensum-icon.component.html',
    styleUrl: './pensum-icon.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PensumIconComponent {
    public value = input.required<string>();
    public tooltip = input<string>();
    public tooltipTranslated = input<boolean>(false);
    public inverted = input<boolean>(false);
    public tooltipPlacement = input<string>('top-left');
    public colorMode = input<ColorMode>();

    public tooltipText = computed(() => {
        const text = this.tooltip();
        const translated = this.tooltipTranslated();

        if (isNullish(text)) {
            return undefined;
        }

        if (translated) {
            return text;
        }

        return this.translateService.instant(text);
    });

    private translateService = inject(Translator);
}
