/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {computed} from '@angular/core';
import type {RequestError} from '@dv/shared/angular';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {IPersisted} from '@dv/shared/code';
import {patchState, signalStoreFeature, withComputed, withMethods, withState} from '@ngrx/signals';
import {addEntity, setAllEntities, updateAllEntities, updateEntity, withEntities} from '@ngrx/signals/entities';
import {defaultItemState, type ItemState, type ItemWithEntity} from './accordion.adapter';
import type {DisplayMode} from '../shared/display-mode';
import type {
    SelectEntityId} from '@ngrx/signals/entities';

export type AccordionSignalState = {
    isLoading: boolean;
    showCreateMode: boolean;
    showDeleteDialog: boolean;
    deleteEntity: EntityId;
    allowMultiple: boolean;
    displayModeAfterUpdate: DisplayMode;
};

export const selectId: SelectEntityId<ItemWithEntity<IPersisted>> = item => item.entity.id;

function createAccordionState(
    options?: Partial<ItemState & {
        allowMultiple: boolean;
        displayModeAfterUpdate: DisplayMode;
    }>,
): AccordionSignalState {
    const isLoading = true;
    const showCreateMode = false;
    const showDeleteDialog = false;
    const allowMultiple = options?.allowMultiple ?? false;
    const displayModeAfterUpdate = options?.displayModeAfterUpdate ?? 'readonly';

    return {isLoading, showCreateMode, showDeleteDialog, deleteEntity: '', allowMultiple, displayModeAfterUpdate};
}

function entitiesToItems<Entity extends IPersisted>(
    entities: Entity[],
    options?: Partial<ItemState>,
): ItemWithEntity<Entity>[] {
    const {isLoading, expanded, displayMode} = {...defaultItemState, ...options};
    const defaults = {isLoading, expanded, displayMode};

    return entities.map(entity => {
        return {...defaults,  entity};
    });
}

// suppress function return type warning, because we want the complex return type to be inferred
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function withAccordionFunctionality<Entity extends IPersisted>() {

    return signalStoreFeature(
        withState(createAccordionState()),
        withEntities<ItemWithEntity<Entity>>(),
        withComputed(store => ({
            isEmpty: computed<boolean>(() => {
                const entities = store.entities();
                const loading = store.isLoading();

                return entities.length === 0 && !loading;
            }),
            expanded: computed(() => store.entities().filter(item => item.expanded)),
        })),
        withMethods(store => ({
            initWithEntities(entities: Entity[]) {
                patchState(store, setAllEntities(entitiesToItems(entities), {selectId}));
            },
            setAllExpandedFalse() {
                patchState(store, updateAllEntities({expanded: false}, {selectId}));
            },
            add(entity: Entity) {
                patchState(store, addEntity({...defaultItemState, entity}, {selectId}));
            },
            update(entity: Entity) {
                patchState(store, updateEntity({
                        id: entity.id,
                        changes: {
                            isLoading: false,
                            displayMode: store.displayModeAfterUpdate(),
                            entity,
                            expanded: false,
                        }},
                    {selectId},
                ));
            },
            setItemIsLoadingTrue(id: EntityId) {
                patchState(store, updateEntity({id, changes: {isLoading: true}}, {selectId}));
            },
            setItemIsLoadingFalse(id: EntityId) {
                patchState(store, updateEntity({id, changes: {isLoading: false}}, {selectId}));
            },
            setExpandedTrue(id: EntityId) {
                if (!store.allowMultiple()) {
                    patchState(store, updateAllEntities({expanded: false}, {selectId}));
                }
                patchState(store, updateEntity({id, changes: {expanded: true}}, {selectId}));
            },
            setExpandedFalse(id: EntityId) {
                patchState(store, updateEntity({id, changes: {expanded: false}}, {selectId}));
            },
            setEditMode(id: EntityId) {
                patchState(store, updateEntity({id, changes: {displayMode: 'edit'}}, {selectId}));
            },
            setReadonlyMode(id: EntityId) {
                patchState(store, updateEntity({id, changes: {displayMode: 'readonly'}}, {selectId}));
            },
            enableCreateMode() {
                patchState(store, {showCreateMode: true});
            },
            disableCreateMode() {
                patchState(store, {showCreateMode: false});
            },
            startLoading() {
                patchState(store, {isLoading: true});
            },
            finishLoading() {
                patchState(store, {isLoading: false});
            },
            setShowDeleteDialog(id: EntityId) {
                patchState(store, {showDeleteDialog: true, deleteEntity: id});
            },
            hideDeleteDialog() {
                patchState(store, {showDeleteDialog: false, deleteEntity: undefined});
            },
        })),
        withMethods(store => ({
            toggleExpanded(id: EntityId) {
                const toggle = store.entityMap()[id].expanded ?
                    store.setExpandedFalse :
                    store.setExpandedTrue;

                toggle(id);
            },
            success({id}: RequestError) {
                store.setItemIsLoadingFalse(id);
            },
            error({id}: RequestError) {
                store.setItemIsLoadingFalse(id);
            },
        })),
    );
}
