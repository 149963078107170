/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {RequestError, Update} from '@dv/shared/angular';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {IPersisted} from '@dv/shared/code';
import {createAdapter, joinAdapters} from '@state-adapt/core';
import {booleanAdapter} from '@state-adapt/core/adapters';
import type {DisplayMode} from '../shared/display-mode';

export interface ItemState {
    isLoading: boolean;
    expanded: boolean;
    displayMode: DisplayMode;
}

export interface ItemWithEntity<Entity> extends ItemState {
    entity: Entity;
}

export type AccordionContextWithEntity<Entity> = Record<EntityId, ItemWithEntity<Entity>>;

export type AccordionState<Entity> = {
    isLoading: boolean;
    showCreateMode: boolean;
    showDeleteDialog: boolean;
    allowMultiple: boolean;
    displayModeAfterUpdate: DisplayMode;
    ids: EntityId[];
    items: AccordionContextWithEntity<Entity>;
};

export const defaultItemState: Readonly<ItemState> = {isLoading: false, expanded: false, displayMode: 'readonly'};

export function createAccordionState<Entity extends IPersisted>(
    entities?: Entity[],
    options?: Partial<ItemState & {
        allowMultiple: boolean;
        displayModeAfterUpdate: DisplayMode;
    }>,
): AccordionState<Entity> {
    const isLoading = true;
    const showCreateMode = false;
    const showDeleteDialog = false;
    const allowMultiple = options?.allowMultiple ?? false;
    const displayModeAfterUpdate = options?.displayModeAfterUpdate ?? 'readonly';
    const ids = entities ? entities.map(e => e.id) : [];
    const items = entities ? entitiesToItems(entities, options) : {};

    return {isLoading, showCreateMode, showDeleteDialog, allowMultiple, displayModeAfterUpdate, ids, items};
}

function entitiesToItems<Entity extends IPersisted>(
    entities: Entity[],
    options?: Partial<ItemState>,
): AccordionContextWithEntity<Entity> {
    const {isLoading, expanded, displayMode} = {...defaultItemState, ...options};
    const defaults = {isLoading, expanded, displayMode};

    const items: AccordionContextWithEntity<Entity> = {};
    entities.forEach(entity => {
        items[entity.id] = {...defaults, entity};
    });

    return items;
}

type AtLeastOne<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> & U[keyof U];

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function createAccordionAdapter<Entity extends IPersisted>() {
    const itemsAdapter = createAdapter<AccordionContextWithEntity<Entity>>()({});

    const adapter = joinAdapters<AccordionState<Entity>>()({
        isLoading: booleanAdapter,
        showCreateMode: booleanAdapter,
        showDeleteDialog: booleanAdapter,
        allowMultiple: booleanAdapter,
        displayModeAfterUpdate: createAdapter<DisplayMode>()({}),
        ids: createAdapter<EntityId[]>()({}),
        items: itemsAdapter,
    })({
        // returns entities in the same order as they were inserted
        all: state => state.ids.map(id => state.items[id]),
        isEmpty: state => state.ids.length === 0 && !state.isLoading,
    })({
        expanded: state => state.all.filter(item => item.expanded),
        entities: state => state.all.map(a => a.entity),
    })(([_selectors, _reactions]) => ({
        initWithEntitiesAndState: (
            state,
            payload: {
                entities: Entity[];
                defaultItemState: Partial<ItemState>;
            },
        ): AccordionState<Entity> => {
            // we keep the ids seperate, to preserve the order of the entities
            const ids = payload.entities.map(e => e.id);
            const items = entitiesToItems(payload.entities, payload.defaultItemState);

            return {...state, ids, items};
        },
    }))(([selectors, reactions]) => ({
        initWithEntities: (state, entities: Entity[]): AccordionState<Entity> => {
            return reactions.initWithEntitiesAndState(state, {entities, defaultItemState});
        },
        setAllExpandedFalse: (state): AccordionState<Entity> => {
            const expanded = selectors.expanded(state);
            const items = expanded.length ? {...state.items} : state.items;
            expanded.forEach(item => {
                items[item.entity.id] = {...item, expanded: false};
            });

            return {...state, items};
        },
        partialUpdate: (state, {id, payload}: Update<AtLeastOne<ItemWithEntity<Entity>>>): AccordionState<Entity> => {
            const itemState: ItemWithEntity<Entity> = {
                ...state.items[id],
                ...payload,
            };

            return {...state, items: {...state.items, [id]: itemState}};
        },
    }))(([_selectors, reactions]) => ({
        add: (state, entity: Entity): AccordionState<Entity> => {
            return {
                ...state,
                ids: [...state.ids, entity.id],
                items: {...state.items, [entity.id]: {...defaultItemState, entity}},
            };
        },
        update: (state, {id, payload}: Update<Entity>): AccordionState<Entity> => {
            const item = {
                isLoading: false,
                displayMode: state.displayModeAfterUpdate,
                entity: payload,
                expanded: false,
            };

            return reactions.partialUpdate(state, {id, payload: item});
        },
        delete: (state, {id}: { id: EntityId }): AccordionState<Entity> => {
            const newItems = {...state.items};
            delete newItems[id];

            return {
                ...state,
                ids: state.ids.filter(i => i !== id),
                items: newItems,
            };
        },
        setItemIsLoadingTrue: (state, id: EntityId): AccordionState<Entity> => {
            return reactions.partialUpdate(state, {id, payload: {isLoading: true}});
        },
        setItemIsLoadingFalse: (state, id: EntityId): AccordionState<Entity> => {
            return reactions.partialUpdate(state, {id, payload: {isLoading: false}});
        },
        setExpandedTrue: (state, id: EntityId): AccordionState<Entity> => {
            const closeOthers = state.allowMultiple ? state : reactions.setAllExpandedFalse(state);

            return reactions.partialUpdate(closeOthers, {id, payload: {expanded: true}});
        },
        setExpandedFalse: (state, id: EntityId): AccordionState<Entity> => {
            return reactions.partialUpdate(state, {id, payload: {expanded: false}});
        },
        setEditMode: (state, id: EntityId): AccordionState<Entity> => {
            return reactions.partialUpdate(state, {id, payload: {displayMode: 'edit'}});
        },
        setReadonlyMode: (state, id: EntityId): AccordionState<Entity> => {
            return reactions.partialUpdate(state, {id, payload: {displayMode: 'readonly'}});
        },
    }))(([_selectors, reactions]) => ({
        toggleExpanded: (state, id: EntityId): AccordionState<Entity> => {
            return state.items[id].expanded ?
                reactions.setExpandedFalse(state, id) :
                reactions.setExpandedTrue(state, id);
        },
        success: (state, {id}: Update<unknown>): AccordionState<Entity> => {
            return reactions.setItemIsLoadingFalse(state, id);
        },
        error: (state, {id}: RequestError): AccordionState<Entity> => {
            return reactions.setItemIsLoadingFalse(state, id);
        },
    }))();

    return adapter;
}
