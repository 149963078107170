<div class="collapsible-panel-container"
     [ngClass]="{ 'first-container': isFirst(), 'last-container': isLast(), 'compact-panel': compact() }">
    <button class="folding-icon btn btn-link floating-text"
            [attr.aria-label]="(isCollapsed() ? 'COMMON.EINBLENDEN' : 'COMMON.AUSBLENDEN') | transloco"
            (click)="toggle()"
            [hidden]="isDisabled()">
        <dvlib-icon [iconName]="isCollapsed() ? 'zugeklappt' : 'aufgeklappt'" iconWidth="fixed"/>
    </button>
    <div class="header-and-content">
        @if (showHeader()) {
            <ng-content select="[panel-header]"/>
        }
        @if (showContent()) {
            <ng-content select="[panel-content]"/>
        }
    </div>
</div>
