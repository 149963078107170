/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {computed} from '@angular/core';
import type {RequestError} from '@dv/shared/angular';
import type {EntityId} from '@dv/shared/backend/model/entity-id';
import type {IPersisted, SupportedDateTypes} from '@dv/shared/code';
import {patchState, signalStoreFeature, withComputed, withMethods, withState} from '@ngrx/signals';
import {
    addEntity,
    removeEntity,
    setAllEntities,
    updateAllEntities,
    updateEntity,
    withEntities,
} from '@ngrx/signals/entities';
import type {DisplayMode} from '../../shared/display-mode';

export const defaultLimitedItemState: Readonly<GueltigkeitVerlaufItemState> = {
    isLoading: false,
    expanded: false,
    displayMode: 'readonly',
    terminated: false,
};

export interface GueltigkeitVerlaufItemState {
    isLoading: boolean;
    expanded: boolean;
    terminated: boolean;
    displayMode: DisplayMode;
}

export type GueltigkeitsVerlaufEntity = IPersisted & {
    gueltigAb: SupportedDateTypes | null;
    gueltigBis: SupportedDateTypes | null;
};

export interface GueltigkeitVerlaufItemWithEntity<Entity extends GueltigkeitsVerlaufEntity>
    extends GueltigkeitVerlaufItemState {
    entity: Entity;
}

const selectId = <Entity extends GueltigkeitsVerlaufEntity,
    T extends GueltigkeitVerlaufItemWithEntity<Entity>>(item: T): EntityId => item.entity.id;

export type GueltigkeitVerlaufState = {
    deleteEntity: string;
    showCreateMode: boolean;
    isDeleteDialogVisible: boolean;
    isTerminateDialogVisible: boolean;
    isLoading: boolean;
};

export function createGueltigkeitVerlaufState(): GueltigkeitVerlaufState {
    return {
        deleteEntity: '',
        showCreateMode: false,
        isDeleteDialogVisible: false,
        isTerminateDialogVisible: false,
        isLoading: false,
    };
}

function entitiesToItems<Entity extends GueltigkeitsVerlaufEntity>(
    entities: Entity[],
): GueltigkeitVerlaufItemWithEntity<Entity>[] {

    return entities.map(entity => {
        const {isLoading, expanded, displayMode, terminated} = {...defaultLimitedItemState, ...entity};
        const defaults = {isLoading, expanded, displayMode, terminated};

        return {...defaults, entity};
    });
}

// suppress function return type warning, because we want the complex return type to be inferred
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function withGueltigkeitVerlaufFunctionality<Entity extends GueltigkeitsVerlaufEntity>() {

    return signalStoreFeature(
        withState(createGueltigkeitVerlaufState()),
        withEntities<GueltigkeitVerlaufItemWithEntity<Entity>>(),
        withComputed(store => ({
            isEmpty: computed<boolean>(() => {
                const entities = store.entities();
                const loading = store.isLoading();

                return entities.length === 0 && !loading;
            }),
            expanded: computed(() => store.entities().filter(item => item.expanded)),
        })),
        withMethods(store => ({
            initWithEntities(entities: Entity[]) {
                patchState(store, setAllEntities(entitiesToItems(entities), {selectId}));
            },
            setAllExpandedFalse() {
                patchState(store, updateAllEntities({expanded: false}, {selectId}));
            },
            addEntity(entity: Entity) {
                patchState(store, addEntity({...defaultLimitedItemState, entity}, {selectId}));
            },
            updateEntity(entity: Entity) {
                patchState(store, updateEntity({
                    id: entity.id,
                    changes: {
                        isLoading: false,
                        entity,
                        displayMode: 'readonly',
                    },
                }, {selectId}));
            },
            removeEntity(id: EntityId) {
                patchState(store, removeEntity(id));
            },
            setItemIsLoadingTrue(id: EntityId) {
                patchState(store, updateEntity({id, changes: {isLoading: true}}, {selectId}));
            },
            setItemIsLoadingFalse(id: EntityId) {
                patchState(store, updateEntity({id, changes: {isLoading: false}}, {selectId}));
            },
            setExpandedTrue(id: EntityId) {
                patchState(store, updateEntity({id, changes: {expanded: true}}, {selectId}));
            },
            setExpandedFalse(id: EntityId) {
                patchState(store, updateEntity({id, changes: {expanded: false}}, {selectId}));
            },
            setEditMode(id: EntityId) {
                patchState(store, updateEntity({id, changes: {displayMode: 'edit'}}, {selectId}));
            },
            setReadonlyMode(id: EntityId) {
                patchState(store, updateEntity({id, changes: {displayMode: 'readonly'}}, {selectId}));
            },
            enableCreateMode() {
                patchState(store, {showCreateMode: true});
            },
            disableCreateMode() {
                patchState(store, {showCreateMode: false});
            },
            startLoading() {
                patchState(store, {isLoading: true});
            },
            finishLoading() {
                patchState(store, {isLoading: false});
            },
            showDeleteDialog(id: EntityId) {
                patchState(store, {isDeleteDialogVisible: true, deleteEntity: id});
            },
            hideDeleteDialog() {
                patchState(store, {isDeleteDialogVisible: false, deleteEntity: undefined});
            },
            hideTerminateDialog() {
                patchState(store, {isTerminateDialogVisible: false, deleteEntity: undefined});
            },
            showTerminateDialog() {
                patchState(store, {isTerminateDialogVisible: true});
            },
        })),
        withMethods(store => ({
            toggleExpanded(id: EntityId) {
                const toggle = store.entityMap()[id].expanded ?
                    store.setExpandedFalse :
                    store.setExpandedTrue;

                toggle(id);
            },
            success({id}: RequestError) {
                store.setItemIsLoadingFalse(id);
            },
            error({id}: RequestError) {
                store.setItemIsLoadingFalse(id);
            },
        })),
    );
}

