@if (state(); as state) {
    <dvlib-dialog [open]="(state.open$ | async) || false" (dialogClose)="dialogClose.emit()">
        <ng-template>
            <form (ngSubmit)="state.confirm(sortListItems)" novalidate>
                <div class="modal-body">
                    <h1 [transloco]="state.dialogTitleKey"></h1>
                    <dvlib-sort-list [items]="sortListItems"
                                     [itemRef]="templateRef()"
                                     (itemsChange)="itemsChanged($event)">
                    </dvlib-sort-list>
                </div>
                <div class="modal-footer big-abstand-top">
                    <dv-submit-cancel-buttons [isLoading]="(state.isLoading$ | async) || false"
                                              [submitLabel]="'COMMON.SPEICHERN'"
                                              (cancel)="state.cancel()">
                    </dv-submit-cancel-buttons>
                </div>
            </form>
        </ng-template>
    </dvlib-dialog>
}
