/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, input, OnChanges, output, SimpleChanges, TemplateRef} from '@angular/core';
import {FormsModule} from '@angular/forms';
import type {SortListTemplateContext} from '@dv/shared/angular';
import {DialogComponent, SortListComponent} from '@dv/shared/angular';
import type {FunctionType} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import type {Observable} from 'rxjs';
import {SubmitCancelButtonsComponent} from '../../../submit-cancel-buttons/submit-cancel-buttons.component';

export interface SortListDialogModel<T> {
    items: T[];
    dialogTitleKey: string;
    open$: Observable<boolean>;
    isLoading$: Observable<boolean>;
    confirm: (value: T[]) => unknown;
    cancel: FunctionType;
}

@Component({
    selector: 'dv-sort-list-dialog',
    templateUrl: './sort-list-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        SortListComponent,
        SubmitCancelButtonsComponent,
        DialogComponent,
        TranslocoModule,
    ],
})
export class SortListDialogComponent<T> implements OnChanges {
    public state = input<SortListDialogModel<T>>();
    public templateRef = input.required<TemplateRef<SortListTemplateContext<T>>>();
    public readonly dialogClose = output<void>();

    public sortListItems: T[] = [];

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.state?.currentValue?.items) {
            this.sortListItems = changes.state?.currentValue.items;
        }
    }

    public itemsChanged(items: T[]): void {
        this.sortListItems = items;
    }
}
