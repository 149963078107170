import {ConnectedPosition, Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {
    Directive,
    effect,
    ElementRef,
    inject,
    input,
    InputSignal,
    OnDestroy,
    output,
    TemplateRef,
    untracked,
    ViewContainerRef,
} from '@angular/core';
import {checkPresent, isPresent} from '@dv/shared/code';
import {Subscription} from 'rxjs';

const POSITIONS: ConnectedPosition[] = [
    // position below element
    {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
        offsetY: 15,
        panelClass: 'below',
    },
    // position above element
    {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
        offsetY: -15,
        panelClass: 'above',
    },
];

@Directive({
    selector: '[dvOverlay]',
    standalone: true,
})
export class OverlayDirective<T = unknown> implements OnDestroy {
    public overlayTemplate = input<TemplateRef<T>>();
    public overlayOpen: InputSignal<boolean> = input.required<boolean>();
    public overlayContext = input<T>();

    public readonly closeOverlay = output<void>();

    private overlayRef?: OverlayRef;
    private subscription?: Subscription;

    // noinspection JSUnusedLocalSymbols
    private toggleOverlay = effect(() => {
        if (this.overlayOpen()) {
            untracked(() => this.openOverlay());
        } else {
            this.overlayRef?.detach();
        }
    });

    private element = inject(ElementRef);
    private viewContainer = inject(ViewContainerRef);
    private readonly overlay = inject(Overlay);

    private openOverlay(): void {
        const overlayTemplate = this.overlayTemplate();

        if (!isPresent(overlayTemplate)) {
            return;
        }

        const config = new OverlayConfig({
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-transparent-backdrop',
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
            positionStrategy: this.overlay
                .position()
                .flexibleConnectedTo(checkPresent(this.element))
                .withPositions(POSITIONS),
        });

        this.overlayRef?.detach();
        this.overlayRef = this.overlay.create(config);
        this.subscription = this.overlayRef
            .backdropClick()
            .subscribe(() => {
                this.closeOverlay.emit();
            });

        const portal = new TemplatePortal(overlayTemplate, this.viewContainer, this.overlayContext());

        this.overlayRef.attach(portal);
    }

    public ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }
}
