@if (value(); as value) {
    <div class="circle"
         [ngClass]="{
                inverted: inverted(),
                normal: colorMode() === 'normal',
                bright: colorMode() === 'bright',
                contrast: colorMode() === 'contrast',
                guide: colorMode() === 'guide'
            }"
         [tooltip]="tooltipText()">
        {{ value }}
    </div>
}
